import React, {useEffect, useState} from 'react';
import {useTranslation} from "../lang/TranslationContext";
import axios from "axios";
import {useTonConnectUI, useTonWallet} from "@tonconnect/ui-react";
import { useNavigate } from "react-router-dom";

const copyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log(1);
      alert('Success')
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }

  

const Modals = ({tg, handleOverlayClick, activeModal, currentBalance, openModal, currentWallet, fetchHistory}) => {
    const  payWallet = "UQAKHNvO1UcBPhlmLoBdtQXg7RQ1Mu5oGD3WuB2tFbu3WiWg"
    const {t} = useTranslation();
    const wallet = useTonWallet();
    const [tonConnectUi, connected ] = useTonConnectUI();
    const [amount, setAmount] = useState(10);
    const [out, setOut] = useState(1);
    const navigate = useNavigate();

    const setWallet = async (walletAddress) => {
            try {
            const initData = tg && tg.initData ? tg.initData : null;
                    const response = await fetch(`${process.env.REACT_APP_API_URL}/setWallet.php`, {
                            method: 'POST',
                            headers: {
                                    'Authorization': initData || '',
                                    'Content-Type': 'application/json'
                            },
                            body: JSON.stringify({'wallet': walletAddress}),
                    });

                    const result = await response.json();
                    if (result.status === 'success') {
                   
                    } else {
                        alert(result.message)
                    }
            } catch (error) {
                console.log(error)
            }
    };

    useEffect(()=>{
        if (wallet) {
            if(currentWallet !== false) {
                const walletAddress = wallet.account.address; 
                if(walletAddress !== currentWallet) {
                    setWallet(walletAddress)
                }
            }
           
        }
    }, [wallet, currentWallet])

    const sendTransaction = async (amountInTon) => {
        if (!connected || !wallet) {
          console.error('Wallet is not connected');
          return;
        }
    
        try {
          const transaction = {
            validUntil: Math.floor(Date.now() / 1000) + 3600, 
            messages: [
              {
                address: payWallet,
                amount: (amountInTon * 1e9).toString(), 
                payload: '',
              },
            ],
          };
    
          const result = await tonConnectUi.sendTransaction(transaction);
          navigate("/");
        } catch (error) {
          console.log('Failed to send transaction:', error);
        }
      };


    const withdrawMoney = async () => {
        try {
          const initData = tg && tg.initData ? tg.initData : null;
                const response = await fetch(`${process.env.REACT_APP_API_URL}/createWithdraw.php`, {
                        method: 'POST',
                        headers: {
                                'Authorization': initData || '',
                                'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({ 
                          'currency_id': 3, 
                          'amount' : out
                        }),
                });

                const result = await response.json();
                if (result.status === 'success') {
                  alert(result.message)
                  fetchHistory()
                } else {
                     alert(result.message)
                }
        } catch (error) {
                console.log(error)
        }
};


    return (
        <>
            <div className={`overlay fixed-block ${activeModal ? 'active' : ''}`} data-overlay
                 onClick={handleOverlayClick}>
                

                <div className={`modal ${activeModal === 'wallet' ? 'active' : ''}`} data-popup="wallet"
                     style={activeModal === 'wallet' ? {opacity: 1, display: 'flex', transition: '200ms'} : {}}>
                    <div className="modal__top">
                        <span className="modal__title">{t('Specify TON wallet')}</span>
                        <p>{t('This amount will be sent to your TON wallet.')}</p>

                        <div className="main-info">
                            <div className="main-info__box">
                                <div className="main-info__inner">
                                    <div className="main-info__icon">
                                        <img width='47' height='47' src='img/index/ton.png' alt='image'/>
                                    </div>
                                    <span className="main-info__title">{t('Balance')}:</span>
                                    <span className="main-info__value">
                                      <b>{currentBalance}</b> TON
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="modal__box">
                        <form className="main-form withdraw">
                            <div className="main-form__inner">
                                <span className="main-form__balance">{t('Amount')}:</span>
                                <label htmlFor="coin" className="main-form__label">
                                    <input value={out}  onChange={(event) => setOut(event.target.value)}  type="number" min="100" name="coin" id="coin" className="main-form__input"
                                           placeholder="10"/>

                                    <span className="main-form__icon">
                            <img width="24" height="24" src="./img/index/ton.png" alt="icon"/>
                        </span>
                                </label>
                                <span className="main-form__text">{t('Minimum withdrawal amount')}: 0,35 TON</span>
                            </div>

                            <div className="main-form__btns">
                            {wallet ? (<>
                                <button onClick={()=>{withdrawMoney()}} type="button" className="blue-btn">{t('Withdraw funds')}
                                    <svg width='24' height='24'>
                                        <use href='img/sprite/sprite.svg#bolt'></use>
                                    </svg>
                                </button>
                                <button type="button" onClick={()=>{tonConnectUi.disconnect()}}>Disconnect</button>
                            </>
) : (<>
                            <p>{t('Connect your TON wallet for payment')}</p>
                            <button type="button" onClick={() => tonConnectUi.openModal()} className="blue-btn" data-btn-inner="wallet">{t('Connect Wallet')}
                                <img width='24' height='24' src='img/index/ton.png' alt='image'/>
                            </button>
</>)}
                                
                                <a className="transparent-btn close">{t('Cancel')}</a>
                            </div>

                        </form>
                    </div>
                </div>

                <div className={`modal ${activeModal === 'connect' ? 'active' : ''}`} data-popup="connect"
                     style={activeModal === 'connect' ? {opacity: 1, display: 'flex', transition: '200ms'} : {}}>
                    <div className="modal__top modal__top--mode">
                        <span className="modal__title">{t('Order details')}</span>
                        <ul className="modal__list">
                            <li className="modal__item">
                                <span className="modal__text">{t('Power')}: <b>{Number(amount * 10000).toFixed(2)} GH/s ⚡</b></span>
                            </li>
                            <li className="modal__item">
                                <span className="modal__text">{t('Rental period')}: <b>30 {t('days')}</b></span>
                            </li>
                            <li className="modal__item">
                                <span className="modal__text">{t('Total income')}: <b>~{Number(amount * 1.5).toFixed(4)} TON</b></span>
                            </li>
                            <li className="modal__item">
                                <span className="modal__text">{t('Dailty income')}:<b>~{Number(amount * 1.5 /30).toFixed(4)} TON</b></span>
                            </li>
                            <li className="modal__item">
                                <span className="modal__text">{t('Price')}: <b> {amount} TON</b></span>
                            </li>
                        </ul>

                        <div className="modal__wrapp">
                            <span className="modal__title">{t('Payment')}</span>
                            {wallet ? (<>
  <button type="button" onClick={() => {sendTransaction(amount)}} className="blue-btn" data-btn-inner="wallet">{t('Pay')}
                                <img width='24' height='24' src='img/index/ton.png' alt='image'/>
                            </button>
                            <span className="modal__pretitle">{t('or')}</span>

                            <p>{t('Send to the specified deposit address from your wallet')}.</p>
                            </>
) : (<>

                            <p>{t('Connect your TON wallet to pay for your order')}</p>
                            <button type="button" onClick={() => tonConnectUi.openModal()} className="blue-btn" data-btn-inner="wallet">{t('Connect Wallet')}
                                <img width='24' height='24' src='img/index/ton.png' alt='image'/>
                            </button>
</>)}

                            <a className="transparent-btn close">{t('Cancel')}</a>

                           
                        </div>
                    </div>

                    {wallet ? (<form className="main-form withdraw">
                        <div className="main-form__box">
                            <div className="main-form__qr">
                                <img width='182' height='184' src='img/wallet_qr.png' alt='image'/>
                            </div>

                            <span className="main-form__value"><b>{amount}</b> TON</span>

                            <label className="main-form__label" htmlFor="address_1" data-parent-copy>
                                <input onClick={()=>{copyText(payWallet)}} readOnly type="text" name="address_1" id="address_1" className="main-form__input"
                                       value={payWallet}/>

                                <button type="button"  onClick={()=>{copyText(payWallet)}} className="copy-btn" data-copy>
                                    <img width='24' height='24' src='img/sprite/copy.svg' alt='image'/>
                                </button>
                            </label>
                        </div>
                    </form>) : <></> }
                    
                </div>

                <div className={`modal ${activeModal === 'buy' ? 'active' : ''}`} data-popup="buy"
                     style={activeModal === 'buy' ? {opacity: 1, display: 'flex', transition: '200ms'} : {}}>
                    <div className="modal__top">
                        <span className="modal__title">{t('Buy boost')}</span>
                        <p>{t('Here you can rent mining power for 30 days')}.</p>
                        <p>{t('The investment return is 5%/day or 150% in 30 days.')}</p>
                    </div>

                    <div className="modal__box">
                        <form className="main-form withdraw">
                            <div className="main-form__inner">
                                <span className="main-form__balance">{t('Amount')}:</span>
                                <label htmlFor="coin" className="main-form__label">
                                    <input type="number" value={amount} onChange={(event) => setAmount(event.target.value)}  min="1" name="coin" id="coin" className="main-form__input"
                                           placeholder="10"/>

                                    <span className="main-form__icon">
                            <img width="24" height="24" src="./img/index/ton.png" alt="icon"/>
                        </span>
                                </label>
                                <span className="main-form__text">{t('Min amount')}: 1 TON</span>
                            </div>

                            <div className="main-form__btns">
                                <button type="button" onClick={()=> {openModal('connect')}} className="blue-btn">{t('Buy')} Th/s
                                    <svg width='24' height='24'>
                                        <use href='img/sprite/sprite.svg#bolt'></use>
                                    </svg>
                                </button>
                                <a className="transparent-btn close">{t('Cancel')}</a>
                            </div>

                        </form>
                    </div>
                </div>


            </div>
        </>
    );
};

export default Modals;
