import React, {useEffect, useState} from 'react';
import WithdrawItem from '../components/WithdrawItem';
import {useTranslation} from "../lang/TranslationContext";


function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const year = String(date.getFullYear()).slice(-2); // Get last 2 digits of the year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}/${month}/${year}`;
}

function formatStatus(status) {
     let back = 'In progress'
    if (status == 1)
        back = "Completed"

    if(status == 2)
        back = "Canceled"

    return back
}


const Withdraw = ({tg, openModal, user, history, fetchHistory}) => {
  const { t } = useTranslation();

    useEffect(() => {
        fetchHistory();
    }, []);



    return (
        <>
            <main>

                <section className="withdraw-section">
                    <div className="container">
                        <div className="withdraw-section__box">

                            <div className="main-info">
                                <div className="main-info__box">
                                    <div className="main-info__inner">
                                        <div className="main-info__icon">
                                            <img width='47' height='47' src='img/index/ton2.png' alt='image'/>
                                        </div>
                                        <span className="main-info__title">{t('Balance')}:</span>
                                        <span className="main-info__value">
                                          <b>{user && user['balance_3']}</b> TON
                                        </span>
                                    </div>
                                </div>
                                <button type="button" onClick={()=>{openModal('wallet')}} className="transparent-btn">{t('Withdraw')}</button>
                            </div>

                            {history.length == 0 ? (<>
                                <div className="result">
                                    <span className="result__icon">
                                      <img width="77" height="102" src="img/index/search.png" alt="image"/>
                                    </span>
                                    {t('No transactions')}
                                </div>

                            </>) : (<>
                            <ul className="history-list history-list--mode">
                                <li className="history-list__row history-list__row--head">
                                <span className="history-list__name">{t('Date')}</span>
                                    <span className="history-list__name">{t('Amount')}</span>
                                    <span className="history-list__name">{t('Status')}</span>
                                </li>

                                {history && (
                                    <>
                                        {history.map((item) => (
                                                   <>
                                                    <li className="history-list__row">
                                                        <span className="history-list__value">{formatTimestampToDate(item.date)}</span>
                                                        <span className="history-list__value">
                                                            <img width='20' height='20' src='img/index/ton.png' alt='image'/>
                                                            <i>{Number(item.amount).toFixed(2)} TON</i>
                                                        </span>
                                                        <span className="history-list__value">{t(formatStatus(item.status))}</span>
                                                    </li>
                                                   </>
                                                ))}
                                            </>
                                        )}
                               
                                
                            </ul>
                            </>)}
                        </div>
                    </div>
                </section>

            </main>
        </>
    );
};

export default Withdraw;
