import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {useTranslation} from "../lang/TranslationContext";

const Tasks = ({tg}) => {
    const { t } = useTranslation();

    const [tasksHistory, setTasksHistory] = useState([]);
    const fetchTasksHistory = async () => {
        const initData = tg && tg.initData ? tg.initData : null;
            const response = await axios.get(`${process.env.REACT_APP_API_URL}getTasksHistory.php`, 
              {
                headers: {
                  'Authorization': initData,
                  'Content-Type': 'application/json'
              },
            }
            )
            .then(response => {
                setTasksHistory(response.data.history);
            }).catch(error => {
              console.log('Error fetching data:', error);
            });
    };

    const checkTask = async (task_id) => {
        try {
        const initData = tg && tg.initData ? tg.initData : null;
                const response = await fetch(`${process.env.REACT_APP_API_URL}checkTask.php`, {
                        method: 'POST',
                        headers: {
                                'Authorization': initData || '',
                                'Content-Type': 'application/json'
                        },
                        body: JSON.stringify({'task_id': task_id}),
                });

                const result = await response.json();
                if (result.status === 'success') {
                    fetchTasksHistory()
                } else {
                    alert(result.message)
                }
        } catch (error) {
            console.log(error)
        }
};
  

    const doTask = async (task) => {
        window.open(task.link, '_blank');
        setTimeout(() => {
            checkTask(task.id);
        }, 5000); 
    }
    
  
    useEffect(() => {
        fetchTasksHistory();
    }, [tg]);

    const tasks = [
        // {id: 1, name: 'Subscribe to the cnanel', link: 'https://t.me/livemine_news', amount: 250},
        {id: 2, name: 'Activate APP', link: 'https://t.me/LiveMine_two_bot?start=1', title: "LiveMine 2.0", amount: 25},
        {id: 11, name: 'Launch App', link: 'https://t.me/GTap_Off_Bot?start=r_909665945', title: "Play G-Tap", amount: 10},
        {id: 12, name: 'Launch App', link: 'https://t.me/wolfshouses_bot', title: "Claim Wolfs", amount: 10},
        {id: 13, name: 'Join MarsGo', link: 'https://t.me/marsgo_bot/?start=refcode_74cb3080-2fd6-403c-96c5-f5b37f634498', title: "And claim $MBASE", amount: 10},
        {id: 14, name: 'Launch App', link: 'https://t.me/+soaOlQl7VIllZmNi', title: "Best Web3 profjects", amount: 10},
        // {id: 10, name: 'Launch App', link: 'https://t.me/SecretPadBot/app?startapp=r5242050448_LiveMine', title: "SecretPadBot", amount: 10},
        // {id: 9, name: 'Follow App', link: 'https://t.me/ton_music_bot/TonMusic?startapp=940222589', title: "Ton_music_bot", amount: 10},
        // {id: 3, name: 'Join Channel', link: 'https://t.me/garryscrypto', title: "Garryscrypto", amount: 10},
        // {id: 4, name: 'Join Channel', link: 'https://t.me/kopee4ka_k_kopee4ke1', title: "Kopee4ka_k_kopee4ke1", amount: 10},
        // {id: 5, name: 'Join Channel', link: 'https://t.me/crbok', title: "Crbok", amount: 10},
        // {id: 6, name: 'Launch KOLOBOK', link: 'https://t.me/bokgame_bot?start=2077877692', title: "Bokgame_bot", amount: 10},
        // {id: 7, name: 'Follow Channel', link: 'https://t.me/monahinyacrypto', title: "Monahinyacrypto", amount: 10},
        // {id: 8, name: 'Follow Channel', link: 'https://t.me/mr_lee_crypto', title: "Mr_lee_crypto", amount: 10},
    ];

    return (
        <>
            <main>
                <section class="referral-section">
                <div class="container">
                    <div class="referral-section__box">
                        <div class="main-top">
                        <h1 class="title">
                            {t('Tasks')}
                        </h1>
                        </div>
                        <ul class="task-list">
                            {tasks && tasks.map((task) => (<li class="task-list__item">
                            <div class="task-card">
                            <span class="task-card__icon">
                                <svg width='24' height='24'>
                                <use href='img/sprite/sprite.svg?v=2#users'></use>
                                </svg>
                            </span>

                            <div class="task-card__inner">
                                <p>{t(task.name)}</p>
                                {task.link && (<p><a href={task.link} target="_blank">{task.title}</a></p>)}
                                <span class="task-card__value">+<i>{task.amount}</i> ⚡ Gh/s</span>
                            </div>
                            {tasksHistory && (tasksHistory.includes(Number(task.id)) || tasksHistory.includes(String(task.id))) ? (
                                <button type="button" className="transparent-btn">{t('Complete')}</button>
                            ) : (
                                <div style={{textAlgin: "center"}}>
                               {task.link && (
                                <>
                                    <button 
                                    onClick={() => {doTask(task)}} 
                                    type="button" 
                                    className="blue-btn"
                                    >
                                    {t('Open')}
                                    </button>
                                    <br />
                                </>
                                )}

                                {/* <button type="button" className="blue-btn" style={{marginTop: "5px"}} onClick={() => { checkTask(task.id); }}>{t('Check')}</button> */}
                                </div>
                            )}

                            
                            </div>
                        </li>))}
                        
                        {/* <li class="task-list__item">
                            <div class="task-card">
                            <span class="task-card__icon">
                                <svg width='24' height='24'>
                                <use href='img/sprite/sprite.svg#wallet'></use>
                                </svg>
                            </span>

                            <div class="task-card__inner">
                                <p>Buy boost</p>
                                <span class="task-card__value">+<i>2,500</i> ⚡ Gh/s</span>
                            </div>
                            <button class="transparent-btn">Complete</button>
                            </div>
                        </li> */}
                        </ul>
                    </div>
                </div>
                </section>
            </main>

        </>
    );
};

export default Tasks;
