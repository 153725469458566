import React from 'react';


function formatTimestampToDate(timestamp) {
    const date = new Date(timestamp * 1000); // Convert seconds to milliseconds
    const year = String(date.getFullYear()).slice(-2); // Get last 2 digits of the year
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(date.getDate()).padStart(2, '0');
    return `${day}/${month}/${year}`;
}


const FriendItem = ({item, currency}) => {
    console.log('currency', currency)
    let short =  currency.short.toLowerCase()
    if(short == 'btc') {
        short = 'btcc'
    }
    const icon = "img/sprite/sprite.svg#" + short;
    return (
        <>
        <li className="history-list__row" key={item.id}>
                                        <span className="history-list__value">{formatTimestampToDate(item.date)}</span>
                                        <span className="history-list__value"><b>@{item.username}</b></span>
                                        <span className="history-list__value">
                                            <img width='20' height='20' src='img/index/ton.png' alt='image'/>
                                          <b>{Number(item.amount).toFixed(4)}</b>
                                        </span>
                                        <span className="history-list__value">
                                            <img width='20' height='20' src='img/index/ton.png' alt='image'/>
                                            <b>{(Number(item.amount) * item.percent / 100 ).toFixed(4)}</b>
                                        </span>
                                    </li>
                                    </>
    );
};

export default FriendItem;
