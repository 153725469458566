import React, {useState, useEffect} from 'react';
import axios from 'axios';
import FriendItem from '../components/FriendItem';
import {useTranslation} from "../lang/TranslationContext";

const copyText = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      console.log(1);
      alert('Success')
    } catch (err) {
      console.error('Failed to copy: ', err);
    }
  }


const Friends = ({tg}) => {
    const { t } = useTranslation();
    const [userId, setUserId] = useState(null);
    const [referralLink, setReferralLink] = useState('');

    const [list, setList] = useState(null);
    const [countRef, setCountRef] = useState(null);
    const [currencies, setCurrencies] = useState(null);
    const [upliner, setUpliner] = useState(false);

    useEffect(() => {
        let tmpUserId = ''
        if (tg && tg.initDataUnsafe && tg.initDataUnsafe.user) {
            const user = tg.initDataUnsafe.user;
            if (user && user.id) {
                tmpUserId = user.id;
                setUserId(user.id);
            }
            fetchFriends();
        }
        setReferralLink(`https://t.me/LiveMine_app_bot?start=${tmpUserId}`);
    }, [tg]);

  const fetchFriends = async () => {
      const initData = tg && tg.initData ? tg.initData : null;
          const response = await axios.get(`${process.env.REACT_APP_API_URL}getFriends.php`, 
            {
              headers: {
                'Authorization': initData,
                'Content-Type': 'application/json'
            },
          }
          )
          .then(response => {
            setList(response.data.friendsIncome);
            setCountRef(response.data.friendsCount);
            setCurrencies(response.data.currencies);
            setUpliner(response.data.upliner)
          }).catch(error => {
            console.log('Error fetching data:', error);
          });
  };


    return (
        <>
            <main>
                <section className="referral-section">
                    <div className="container">
                        <div className="referral-section__box">
                            <div className="referral-section__wrapp">
                                <div className="main-top">
                                    <h1 className="title">
                                        {t('Referral program')}
                                        <svg width='24' height='24'>
                                            <use href='img/sprite/sprite.svg#i'></use>
                                        </svg>
                                    </h1>
                                    <p>{t('Your upliner')}: {upliner ? upliner.name : '-'}</p>
                                </div>

                                <form class="main-form copy">
                                    <span class="main-form__subtitle">{t('Your Refferal Link')}</span>

                                    <label class="main-form__label" for="address_3" data-parent-copy>
                                        <input onClick={()=>copyText(referralLink)} readonly type="text" name="address_3" id="address_3" class="main-form__input" value={referralLink}/>
                                        <button onClick={()=>copyText(referralLink)} type="button" class="copy-btn" data-copy>
                                        <img width='24' height='24' src='img/sprite/copy.svg' alt='image'/>
                                        </button>
                                    </label>

                                    <button onClick={()=>copyText(referralLink)} type="button" class="main-btn">{t('Invite Friend')}</button>
                                    </form>

                                <ul className="referral-list">
                                    <li className="referral-list__item">
                                        <div className="referral-card">
                                          <span className="referral-card__icon">
                                             <svg width='28' height='28'>
                                              <use href='img/sprite/sprite.svg#icon3'></use>
                                            </svg>
                                          </span>

                                            <div className="referral-card__inner">
                                                <p>{t('Referral')} 1-{t('st')} {t('level')}</p>
                                                <span className="referral-card__value">{countRef && countRef.lvl1Count ? countRef.lvl1Count : 0}</span>
                                            </div>
                                            <div className="referral-card__inner">
                                                <p>{t('Reward')}</p>
                                                <span className="referral-card__value">7%</span>
                                            </div>
                                        </div>
                                    </li>
                                    <li className="referral-list__item">
                                        <div className="referral-card">
                                          <span className="referral-card__icon">
                                             <svg width='28' height='28'>
                                              <use href='img/sprite/sprite.svg#icon4'></use>
                                            </svg>
                                          </span>

                                            <div className="referral-card__inner">
                                                <p>{t('Referral')} 2-{t('st')} {t('level')}</p>
                                                <span className="referral-card__value">{countRef && countRef.lvl2Count ? countRef.lvl2Count : 0}</span>
                                            </div>
                                            <div className="referral-card__inner">
                                                <p>{t('Reward')}</p>
                                                <span className="referral-card__value">3%</span>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>

                            <div className="referral-section__wrapp mode">
                                <h2 className="title">{t('Your referral income')}</h2>
                                <ul className="history-list">
                                    <li className="history-list__row history-list__row--head" key={0}>
                                        <span className="history-list__name">{t('Time')}</span>
                                        <span className="history-list__name">{t('User')}</span>
                                        <span className="history-list__name">{t('Amount')}</span>
                                        <span className="history-list__name">{t('Profit')}</span>
                                    </li>
                                    {currencies && (
                                    <>
                                        {list.map((item) => (
                                                    <FriendItem
                                                        item={item}
                                                        currency={currencies[item.currency_id - 1]}
                                                    />
                                                ))}
                                            </>
                                        )}
                                </ul>

                            </div>


                        </div>
                    </div>
                </section>
            </main>
        </>
    );
};

export default Friends;