import './App.css';
import Footer from "./blocks/Footer";
import RouterList from "./router/RouterList";
import { useTranslation } from './lang/TranslationContext';
import {useEffect, useState} from "react";
import Modals from "./blocks/Modals";
import axios from 'axios';

function App() {

    const [activeModal, setActiveModal] = useState(null);
    const [scrollPosition, setScrollPosition] = useState(0);

    const [currentBalance, setCurrentBalance] = useState(0);
    const [currentWallet, setCurrentWallet] = useState(false);
    const disableScroll = () => {
        const pagePosition = window.scrollY;
        setScrollPosition(pagePosition);
        document.documentElement.style.scrollBehavior = 'none';
        document.body.style.paddingRight = `${window.innerWidth - document.body.offsetWidth}px`;
        document.body.classList.add('dis-scroll');
        document.body.style.top = `-${pagePosition}px`;
    };

    const enableScroll = () => {
        document.body.style.paddingRight = '0px';
        document.body.style.top = 'auto';
        document.body.classList.remove('dis-scroll');
        window.scroll({ top: scrollPosition, left: 0 });
    };



    const openModal = (modalId) => {
        setActiveModal(modalId);
        disableScroll();
    };

    const closeModal = () => {
        setActiveModal(null);
        enableScroll();
    };

    const handleOverlayClick = (e) => {
        if (e.target.dataset.overlay || e.target.classList.contains('close')) {
            closeModal();
        }
    };

    const tg = window.Telegram.WebApp;
    const { t, changeLanguage } = useTranslation();

    useEffect(() => {
        if(tg && tg.initDataUnsafe && tg.initDataUnsafe.user && tg.initDataUnsafe.user.language_code) {

            const newLang = tg.initDataUnsafe.user.language_code || 'en';
            changeLanguage(newLang)
        } else {
            changeLanguage('en')
        }
        tg.ready();
        tg.expand();
        tg.isClosingConfirmationEnabled = true;
    }, [tg]);

    const [history, setHistory] = useState([]);
    const [user, setUser] = useState(false);


    const fetchHistory = async () => {
        const initData = tg && tg.initData ? tg.initData : null;
        const response = await axios.get(`${process.env.REACT_APP_API_URL}getHistory.php`,
            {
                headers: {
                    'Authorization': initData,
                    'Content-Type': 'application/json'
                },
            }
        )
            .then(response => {
                console.log(response.data.history)
                setHistory(response.data.history)
                console.log('response.data', response.data)
                setUser(response.data.user)
                setCurrentBalance(response.data.user['balance_3'])
            }).catch(error => {
                console.log('Error fetching data:', error);
            });
    };

    return (
    <>
            <RouterList tg={tg} openModal={openModal} setCurrentBalance={setCurrentBalance} setCurrentWallet={setCurrentWallet} actualUser={user} history={history} fetchHistory={fetchHistory}/>
            <Footer />
            <Modals user={user} fetchHistory={fetchHistory} tg={tg} handleOverlayClick={handleOverlayClick} currentWallet={currentWallet} activeModal={activeModal} currentBalance={currentBalance} openModal={openModal}/>
    </>
  );
}

export default App;
