import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router } from 'react-router-dom';
import { TranslationProvider } from './lang/TranslationContext';
import {TonConnectUIProvider, THEME, TonConnectButton} from "@tonconnect/ui-react";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <TonConnectUIProvider
        manifestUrl="https://livemine.app/api/manifest.json?v=2"
        actionsConfiguration={{
            twaReturnUrl: 'https://t.me/LiveMine_app_bot/app'
        }}
    >
      <TranslationProvider>
          <Router>
              <App />
          </Router>
      </TranslationProvider>
    </TonConnectUIProvider>
);

